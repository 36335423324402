import React from "react"
import "../scss/NewsListPage.scss"
import Seo from "../components/seo/seo"

import NewsListPage from "../shared/news-list/NewsListPage"
import SocialMedias from "../shared/social-medias/SocialMedias"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"

const NewsList = props => {
  let resultNews = props.pageContext.news
  const menu = props.pageContext.menu
  const staticHeaderPage = props.pageContext.staticHeaderPage
  const staticNewsPage = props.pageContext.staticNewsPage
  for (let newsItem of resultNews) {
    newsItem.resolvedPath = newsItem.engTitle
  }

  return (
    <div>
      <div className="container">
        <Header
          menu={menu}
          siteTitle={staticNewsPage["<title>"]}
          contents={staticHeaderPage}
          displayLogoLinks={false}
        />
        <Seo
          keywordsContent={staticNewsPage["<meta> keywords"]}
          descriptionContent={staticNewsPage["<meta> description"]}
          title={staticNewsPage["<title>"]}
        />
        <div className="row mb-6 justify-content-center">
          <div className=" mt-4">
            <h1 className="col-lg-11">{staticNewsPage["Page Header"]}</h1>
            {resultNews && resultNews.length ? (
              <NewsListPage
                items={resultNews}
                itemsInRow={2}
                learnMoreBtnText={staticNewsPage["Details Url Title"]}
              />
            ) : null}
          </div>
          <SocialMedias staticNewsPage={staticNewsPage} />
        </div>
      </div>
      <Footer recaptchaSettings={props.pageContext.recaptchaSettings} staticContactsForm={props.pageContext.staticContactsForm} staticContactsPage={props.pageContext.staticContactsPage} />
    </div>
  )
}

export default NewsList
